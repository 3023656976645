<template>
<AppLayout>
    <template v-slot:appContent>
        <section class="users-list-wrapper">
            <div class="content-header row">
                <div class="col-12 mb-1  mr-4 rounded ">
                    <h3 class=" mb-0 py-2 pl-2 bg-white">Order Settings</h3>
                </div>
            </div>
            <div class="row ">
                <div class="col-12 ">
                    <ul class="nav nav-tabs p-0 m-0 bg-white py-1 pl-1">
                        <li @click="currentState = JSON.parse(JSON.stringify(orderSetting))" v-for="(orderSetting,index) in orderSettings" :key="index" class="nav-item ">
                            <a class="nav-link cursor-pointer" :class="[orderSetting.key == currentState.key ? 'active' : '']">
                                <i class='bx bxs-brightness align-middle'></i>
                                <span class="align-middle text-capitalize">{{(orderSetting.key.replaceAll("_", " ").replace("order", ""))}}</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row ">
                <div class="col-lg-12">
                    <div v-if="currentState.key === 'order_order_number_prefix'" class="card-body bg-white rounded">
                        <div class="">
                            <div class="row">
                                <div class="col-12 col-lg-6">
                                    <div class="form-group">
                                        <div class="">
                                            <label for="">{{(currentState.key.replaceAll("_", " ").replace("order", ""))}}</label>
                                            <input type="text" class="form-control" placeholder="" v-model="currentState.value">
                                        </div>
                                    </div>
                                    <div class="">
                                        <p class="text-danger" v-for="(frontEndError,index) in frontEndErrors" :key="index"> {{frontEndError}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex justify-content-start py-2">
                                <button @click="updateOrderNumberPrefix" type="button" class="btn btn-primary glow mb-0">
                                    <span class="align-middle ml-25">Save Changes</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div v-if="currentState.key === 'order_order_number_suffix'" class="card-body bg-white rounded">
                        <div class="">
                            <div class="row">
                                <div class="col-12 col-lg-6">
                                    <div class="form-group">
                                        <div class="">
                                            <label for="">{{(currentState.key.replaceAll("_", " ").replace("order", ""))}}</label>
                                            <input type="text" class="form-control" placeholder="" v-model="currentState.value">
                                        </div>
                                    </div>
                                    <div class="">
                                        <p class="text-danger" v-for="(frontEndError,index) in frontEndErrors" :key="index"> {{frontEndError}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex justify-content-start py-2">
                                <button @click="updateOrderNumberSuffix" type="button" class="btn btn-primary glow mb-0">
                                    <span class="align-middle ml-25">Save Changes</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div v-if="currentState.key === 'order_delivery_charge'" class="card-body bg-white rounded">
                        <div class="">
                            <div class="row">
                                <div class="col-12 col-lg-5">
                                    <div class="form-group">
                                        <div class="controls">
                                            <label>{{(currentState.key.replaceAll("_", " ").replace("order", ""))}}</label>
                                            <div style="margin-top: .5rem">
                                                <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                                                    <div>
                                                        <input v-model="currentState.value.status" type="checkbox" class="custom-control-input" :id="currentState.key">
                                                        <label class="custom-control-label mr-1" :for="currentState.key"></label>
                                                    </div>
                                                    <span class="font-medium-1">{{ (currentState.value.status) == true ? "Active" : "Inactive" }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between my-1">
                                        <h5>Details</h5>
                                        <button @click="addNewOrderDeliveryCharge" class="btn btn-sm btn-success ">Add </button>
                                    </div>
                                    <div class="form-group">
                                        <div class="row border mb-2 p-2" v-for="(singleDetail,index) in currentState.value.details" :key="index">
                                            <div class="col-md-10">
                                                <div class="row form-group">
                                                    <div class="col-3">
                                                        <p class="mb-0 pb-0">Rate</p>
                                                    </div>
                                                    <div class="col-9">
                                                        <input type="number" class="form-control" placeholder="" v-model="singleDetail.rate">
                                                    </div>
                                                </div>
                                                <div class="row form-group">
                                                    <div class="col-3">
                                                        <p class="mb-0 pb-0">Distance upto</p>
                                                    </div>
                                                    <div class="col-9">
                                                        <input type="number" class="form-control" placeholder="" v-model="singleDetail.distance_upto">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-2 d-flex align-items-center">
                                                <button @click="removeOrderDeliveryCharge(index)" class="btn btn-sm btn-danger">remove</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="">
                                        <p class="text-danger mb-0 pb-0 pt-1" v-for="(frontEndError,index) in frontEndErrors" :key="index"> {{frontEndError}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex justify-content-start py-2">
                                <button @click="updateOrderDeliveryCharge" type="button" class="btn btn-primary glow mb-0">
                                    <span class="align-middle ml-25">Save Changes</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div v-if="currentState.key === 'order_registered_addresses'" class="card-body bg-white rounded">
                        <div class="">
                            <button @click="addNewState()" class="btn btn-sm btn-dark my-2">Add New state</button>
                            <div class="row">
                                <div class="col-12 col-lg-5">
                                    <div class="">
                                        <div class="row form-group border" v-for="(singleState,index) in currentState.value" :key="index">
                                            <div class="col-12 ">
                                                <div class="d-flex justify-content-between my-1">
                                                    <div>
                                                        <button @click="addNewAddress(index)" class="btn btn-sm btn-success ">Add address</button>
                                                        <button @click="removeState(index)" class="btn btn-sm btn-warning ml-1">Remove state </button>
                                                    </div>
                                                </div>
                                                <div class="row py-1 d-flex align-items-center">
                                                    <div class="col-2">
                                                        State Name
                                                    </div>
                                                    <div class="col-8">
                                                        <input class="form-control" type="text" v-model="singleState.state">
                                                    </div>
                                                </div>
                                                <div class="form-group row" v-for="(singleAddress,addressIndex) in singleState.addresses" :key="addressIndex">
                                                    <div class="col-md-10">
                                                        <input type="email" class="form-control" placeholder="" v-model="singleAddress.value">
                                                    </div>
                                                    <div class="col-md-2 d-flex align-items-center">
                                                        <button @click="removeAddress(index,addressIndex)" class="btn btn-sm btn-danger">remove</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="">
                                        <p class="text-danger mb-0 pb-0 pt-1" v-for="(frontEndError,index) in frontEndErrors" :key="index"> {{frontEndError}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex justify-content-start py-2">
                                <button @click="updateOrderRegisteredAddress" type="button" class="btn btn-primary glow mb-0">
                                    <span class="align-middle ml-25">Save Changes</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div v-if="currentState.key === 'order_on_site_maximum_distance'" class="card-body bg-white rounded">
                        <div class="">
                            <div class="row">
                                <div class="col-12 col-lg-5">
                                    <div class="form-group">
                                        <div class="">
                                            <label for="">{{(currentState.key.replaceAll("_", " ").replace("order", ""))}}</label>
                                            <input type="number" class="form-control" placeholder="" v-model="currentState.value">
                                        </div>
                                    </div>
                                    <div class="">
                                        <p class="text-danger" v-for="(frontEndError,index) in frontEndErrors" :key="index"> {{frontEndError}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex justify-content-start py-2">
                                <button @click="updateOrderOnSiteMaximumDistance" type="button" class="btn btn-primary glow mb-0">
                                    <span class="align-middle ml-25">Save Changes</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </template>
</AppLayout>
</template>

<script>
import AppLayout from '@/layouts/backEnd/AppLayout.vue';
import Loader from "@/components/backEnd/mixins/Loader";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import {
    mapActions,
    mapGetters
} from 'vuex';
import {
    parseInt
} from 'lodash';
export default {
    name: "AppointmentSettings",
    components: {
        AppLayout,
    },
    mixins: [ShowToastMessage, Loader],
    data() {
        return {
            getSettingsParams: {
                type: ["order"],
                key: [
                    "order_order_number_prefix",
                    "order_order_number_suffix",
                    "order_delivery_charge",
                    "order_registered_addresses",
                    "order_on_site_maximum_distance",
                ],
            },
            currentState: {},
            frontEndErrors: [],
        }
    },
    methods: {
        ...mapActions({
            getSettings: "appSettings/getSettings",
            putSetting: "appSettings/putSetting",
        }),
        updateAppointmentTimeBaseSurchargeRate(event, index) {
            this.currentState.value.details[index].rate = event.target.value;
        },
        async getSettingList(settingParams) {
            const response = await this.getSettings(settingParams);
            this.loader(false);
            if (response.message) {
                this.showToastMessage(response);
            }
        },
        async updateOrderNumberPrefix() {
            this.frontEndErrors = [];
            let nullChecker = !!(this.currentState.value + '');
            if (!nullChecker) {
                this.frontEndErrors.push("Order Number prefix value is required");
                this.showToastMessage({
                    type: 'error',
                    message: `Order Number prefix value is required `
                });
            } else {
                this.updateSettingByApiCall(this.currentState);
            }
        },
        async updateOrderNumberSuffix() {
            this.frontEndErrors = [];
            let nullChecker = !!(this.currentState.value + '');
            if (!nullChecker) {
                this.frontEndErrors.push("Order Number suffix value is required");
                this.showToastMessage({
                    type: 'error',
                    message: `Order Number suffix value is required`
                });
            } else {
                this.updateSettingByApiCall(this.currentState);
            }
        },
        async updateOrderDeliveryCharge() {
            this.frontEndErrors = [];
            let nullChecker = this.currentState.value.details.map((item) => {
                return (Boolean(item.rate + '') && Boolean(item.distance_upto + ''));
            }).some((item) => item == false)
            if (nullChecker === true) {
                this.frontEndErrors.push("Delivery charge rate, Distance upto value can't be empty  ");
                this.showToastMessage({
                    type: 'error',
                    message: `Delivery charge rate, Distance upto value can't be empty   `
                });
            } else {
                let details = [];
                this.currentState.value.details.forEach((currentItem) => {
                    let obj = {
                        distance_upto: parseInt(currentItem.distance_upto),
                        rate: parseInt(currentItem.rate),
                    }
                    details.push(obj);
                })
                this.updateSettingByApiCall({
                    ...this.currentState,
                    value: {
                        status: this.currentState.value.status == true ? 1 : 0,
                        details: details
                    }
                });
            }
        },
        addNewAddress(stateIndex) {
            this.currentState.value[stateIndex].addresses.push({
                key: '',
                value: ''
            });
        },
        removeAddress(stateIndex, addressIndex) {
            this.currentState.value[stateIndex].addresses = this.currentState.value[stateIndex].addresses.filter((_, index) => index !== addressIndex);
        },
        removeState(stateIndex) {
            this.currentState.value = this.currentState.value.filter((_, index) => index !== stateIndex);
        },
        addNewState() {
            window.scrollTo({
                left: 0,
                top: document.body.scrollHeight,
                behavior: "smooth"
            });
            this.currentState.value.push({
                addresses: [{
                    key: '',
                    value: ''
                }],
                state: "",
            });
        },
        async updateOrderRegisteredAddress() {
            this.frontEndErrors = [];
            let stateNameNullChecker = this.currentState.value.map((item) => {
                return (Boolean((item.state).trim()));
            }).some((item) => item == false);
            if (stateNameNullChecker === true) {
                this.frontEndErrors.push("State Name value can't be empty ");
                this.showToastMessage({
                    type: 'error',
                    message: `State Name value can't be empty  `
                });
            } else {
                // alert(true);
                let orderRegisterAddresses = JSON.parse(JSON.stringify(this.currentState.value));
                orderRegisterAddresses.forEach((currentValue) => {
                    let addressArr = []
                    currentValue.addresses.forEach((item) => addressArr.push(item.value));
                    currentValue.addresses = addressArr;
                });
                this.updateSettingByApiCall({
                    ...this.currentState,
                    value: orderRegisterAddresses
                });
            }
        },
        removeOrderDeliveryCharge(removeItemIndex) {
            if (this.currentState.value.details.length !== 1) {
                this.currentState.value.details = this.currentState.value.details.filter((_, index) => index !== removeItemIndex);
                return;
            }
            this.showToastMessage({
                type: 'error',
                message: `Can't remove this item`
            });
        },
        addNewOrderDeliveryCharge() {
            window.scrollTo({
                left: 0,
                top: document.body.scrollHeight,
                behavior: "smooth"
            });
            this.currentState.value.details.push({
                distance_upto: '',
                rate: ''
            });
        },
        async updateOrderOnSiteMaximumDistance() {
            this.frontEndErrors = [];
            let nullChecker = !!(this.currentState.value + '');
            if (!nullChecker) {
                this.frontEndErrors.push("On site maximum distance value is required");
                this.showToastMessage({
                    type: 'error',
                    message: ` On site maximum distance value is required  `
                });
            } else {
                this.updateSettingByApiCall({
                    ...this.currentState,
                    value: parseInt(this.currentState.value)
                });
            }
        },
        async updateAppointmentServiceDuration() {
            this.frontEndErrors = [];
            let nullChecker = !!(this.currentState.value);
            if (!nullChecker) {
                this.frontEndErrors.push("Service duration value is required");
            } else {
                this.updateSettingByApiCall(this.currentState);
            }
        },
        async updateAppointmentOnlineAppointmentStatus() {
            this.frontEndErrors = [];
            await this.updateSettingByApiCall({
                ...this.currentState,
                value: this.currentState.value == true ? '1' : "0",
            });
        },
        async updateAppointmentSameDaySurcharge() {
            this.frontEndErrors = [];
            let nullChecker = !!(this.currentState.value.rate + '');
            if (!nullChecker) {
                this.frontEndErrors.push("Same day surcharge rate value is required");
            } else {
                this.updateSettingByApiCall({
                    ...this.currentState,
                    value: {
                        status: this.currentState.value.status == true ? 1 : 0,
                        rate: parseInt(this.currentState.value.rate)
                    }
                });
            }
        },
        async updateAppointmentHolyDaySurcharge() {
            this.frontEndErrors = [];
            let nullChecker = !!(this.currentState.value.rate);
            if (!nullChecker) {
                this.frontEndErrors.push("Holyday rate value is required");
            } else {
                this.updateSettingByApiCall({
                    ...this.currentState,
                    value: {
                        status: this.currentState.value.status == true ? 1 : 0,
                        rate: this.currentState.value.rate
                    }
                });
            }
        },
        async updateAppointmentOnlineAppointmentDiscount() {
            this.frontEndErrors = [];
            let nullChecker = (Boolean(this.currentState.value.max_amount + '') == false || Boolean(this.currentState.value.value + '') == false || Boolean(this.currentState.value.type + '') == false);
            if (nullChecker == true) {
                this.frontEndErrors.push("Holyday rate value is required");
            } else {
                this.updateSettingByApiCall({
                    ...this.currentState,
                    value: {
                        ...this.currentState.value,
                        status: this.currentState.value.status == true ? 1 : 0,
                    }
                });
            }
        },
        async updateAppointmentTimeBaseSurcharge() {
            this.frontEndErrors = [];
            let nullChecker = this.currentState.value.details.map((item) => {
                return (Boolean(item.rate + '') && Boolean(item.start_time) && Boolean(item.end_time));
            }).some((item) => item == false)
            if (nullChecker === true) {
                this.frontEndErrors.push("Time Base sur charge rate, start time, end time value can't be empty  ");
            } else {
                // let details = this.currentState.value.details.map((item,index) => {
                //     this.currentState.value.details[index].status  = 
                // });
                let details = [];
                this.currentState.value.details.forEach((currentItem) => {
                    let obj = {
                        status: currentItem.status == true ? 1 : 0,
                        rate: parseInt(currentItem.rate),
                        start_time: currentItem.start_time,
                        end_time: currentItem.end_time
                    }
                    details.push(obj);
                })
                this.updateSettingByApiCall({
                    ...this.currentState,
                    value: {
                        status: this.currentState.value.status == true ? 1 : 0,
                        details: details
                    }
                });
            }
        },
        async updateAppointmentWeekendSurcharge() {
            this.frontEndErrors = [];
            let isDayNull = this.currentState.value.days.length == 0 ? false : true;
            let isRateNull = (Boolean(this.currentState.value.rate));
            console.log(isDayNull, " Day Null")
            console.log(isRateNull, " Rate Null");
            if ((isDayNull == false) || (isRateNull == false)) {
                this.frontEndErrors.push("Weekend sur charge rate & days value can't be empty  ");
            } else {
                this.updateSettingByApiCall({
                    ...this.currentState,
                    value: {
                        status: this.currentState.value.status == true ? 1 : 0,
                        apply_with_holiday: this.currentState.value.apply_with_holiday == true ? 1 : 0,
                        rate: this.currentState.value.rate,
                        days: this.currentState.value.days
                    }
                });
            }
        },
        async updateSettingByApiCall(dataObjParam) {
            this.loader(true);
            const data = {
                type: dataObjParam.type,
                key: dataObjParam.key,
                value: dataObjParam.value,
            }
            const dataObj = {
                id: this.currentState.id,
                data: data
            }
            const response = await this.putSetting(dataObj);
            this.loader(false);
            if (response.message) {
                this.showToastMessage(response);
            }
            if (response.status === 200) {
                this.showToastMessage({
                    message: 'Settings updated',
                    type: 'success'
                });
            }
        },
    },
    computed: {
        ...mapGetters({
            settingOrderOrderNumberSuffix: "appSettings/settingOrderOrderNumberSuffix",
            settingOrderOrderNumberPrefix: "appSettings/settingOrderOrderNumberPrefix",
            settingOrderDeliveryCharge: "appSettings/settingOrderDeliveryCharge",
            settingOrderRegisteredAddresses: "appSettings/settingOrderRegisteredAddresses",
            settingOrderOnSiteMaximumDistance: "appSettings/settingOrderOnSiteMaximumDistance",
        }),
        orderSettings() {
            return [this.settingOrderOrderNumberPrefix, this.settingOrderOrderNumberSuffix, this.settingOrderDeliveryCharge, this.settingOrderRegisteredAddresses, this.settingOrderOnSiteMaximumDistance]
        },
    },
    async mounted() {
        await this.getSettingList(this.getSettingsParams);
        this.currentState = {
            ...this.settingOrderOrderNumberPrefix
        };
    },
    watch: {
        currentState(exactState) {
            this.frontEndErrors = [];
            if (exactState.key == 'order_delivery_charge') {
                this.currentState.value.status = Number(this.currentState.value.status) === 1 ? true : false;
            }
        }
    },
}
</script>

<style scoped>
h6 {
    color: #475F7B !important;
}

.icon-custom-font-size {
    font-size: 20px;
}

ul {
    text-decoration: none;
    list-style: none;
}

.div-exact-active {
    background-color: var(--primary-color);
    color: white !important;
}

.div-exact-active:hover {
    color: white !important;
}

.state-side-bar-nav-item:hover {
    color: var(--primary-color);
}

.state-side-bar-nav-item {
    cursor: pointer;
    color: #475f7b;
}
</style>
